import useTable from "../../../../MainComponents/Hooks/useTable.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function Students() {
  const table = useTable("students", "", "", "", "");

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20  dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title={`Students | Mr Ibrahim Abd Elmasseh`}></HelmetTags>

      <div className="mb-32 flex flex-col items-end gap-20">
        <h2 className="w-full text-4xl font-bold md:text-center">Students</h2>
        <div className="flex w-full gap-10 md:justify-evenly">
          <Link
            to="/admin/students/add-student"
            className="mr-4 rounded-xl border-2 border-secondary order-1 bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary active:scale-90"
          >
            <FontAwesomeIcon className="mr-4" icon={faPlus} />
            Add Student
          </Link>
        </div>
      </div>
      {/**
       * //!---------Table-----
       */}
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default Students;
