import { useParams } from "react-router-dom";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useTable from "../../../../MainComponents/Hooks/useTable.jsx";

const AdminStudentCorrection = () => {
  const { studentID } = useParams();
  const table = useTable("correctionsStudent", "", "", "", "", studentID);

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 sm:px-10 ">
      <HelmetTags title="Correction | Mr Ibrahim Abd Elmasseh"></HelmetTags>
      <h2 className="w-full text-4xl font-bold md:text-center mb-32">
        Correction
      </h2>{" "}
      {/**
       * //!---------Table-----
       */}
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
};

export default AdminStudentCorrection;
