import { useState } from "react";
import SingleCourse from "../../../../MainComponents/SingleCourse";
import SingleChapter from "../../../../MainComponents/SingleChapter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useAuthState } from "../../../../MainComponents/GlobalContext.jsx";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { Loader } from "../../../../MainComponents";
import { Input } from "antd";

function AdminLectures() {
  const navigate = useNavigate();
  const AuthState = useAuthState();
  const [searchInput, setSearchInput] = useState("");
  const [reFetch, setReFetch] = useState(false);

  const [allLecturesData, allLecturesErrors, allLecturesloading] = useAxios(
    process.env.REACT_APP_ADMIN_LECTURES_TAB_API,
    "GET",
    "GET",
    reFetch
  );

  if (allLecturesloading) return <Loader />;

  return (
    <section
      className={`h-full  w-full  px-20 py-20  dark:bg-dark dark:text-light `}
    >
      <HelmetTags title="Lectures | Mr Ibrahim Abd Elmasseh"></HelmetTags>
      <div className="mb-32 flex flex-col items-end gap-20">
        <h2 className="w-full text-4xl font-bold md:text-center">Lectures</h2>
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some(
            (permission) => permission.name === "lecture_add"
          )) && (
          <div className="flex w-full gap-10 md:justify-evenly">
            {/* <button
              onClick={() => navigate("/admin/courses/add-lecture")}
              className="mr-4 rounded-xl border-2 border-secondary order-2 bg-light px-2 py-1 font-semibold text-secondary transition-all duration-300 hover:bg-secondary hover:text-light active:scale-90"
            >
              <FontAwesomeIcon className="mr-4" icon={faPlus} />
              Add lecture
            </button> */}
            <button
              onClick={() => navigate("/admin/courses/add-course")}
              className="mr-4 rounded-xl border-2 border-secondary order-1 bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary active:scale-90"
            >
              <FontAwesomeIcon className="mr-4" icon={faPlus} />
              Add Course
            </button>
          </div>
        )}

        {/* <div className="flex w-full gap-10 md:justify-center sm:flex-col-reverse sm:items-center">
          <button
            onClick={() => {
              setAcademicType("national");
              setAcademiclevel("");
            }}
            className={`min-w-[200px] rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-light hover:text-secondary active:scale-90 ${
              academicType === "national" && "bg-secondary text-light"
            }`}
          >
            National
          </button>

          <button
            onClick={() => {
              setAcademicType("ig");
              setAcademiclevel("");
            }}
            className={`min-w-[200px] rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-light hover:text-secondary active:scale-90 ${
              academicType === "ig" && "bg-secondary text-light"
            }`}
          >
            IG
          </button>
        </div>
        {academicType === "national" && (
          <div className="flex w-full gap-10 md:justify-center md:flex-col sm:items-center">
            <button
              onClick={() => {
                setAcademiclevel("22");
              }}
              className={`rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-light hover:text-secondary active:scale-90 ${
                academiclevel === "22" && "bg-secondary text-light"
              }`}
            >
              Second preparatory
            </button>
            <button
              onClick={() => {
                setAcademiclevel("33");
              }}
              className={`rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-light hover:text-secondary active:scale-90 ${
                academiclevel === "33" && "bg-secondary text-light"
              }`}
            >
              Third Preparatory
            </button>

            <button
              onClick={() => {
                setAcademiclevel("1");
              }}
              className={`rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-light hover:text-secondary active:scale-90 ${
                academiclevel === "1" && "bg-secondary text-light"
              }`}
            >
              First Secondary
            </button>

            <button
              onClick={() => {
                setAcademiclevel("2");
              }}
              className={`rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-light hover:text-secondary active:scale-90 ${
                academiclevel === "2" && "bg-secondary text-light"
              }`}
            >
              Second Secondary
            </button>

            <button
              onClick={() => {
                setAcademiclevel("3");
              }}
              className={`rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-light hover:text-secondary active:scale-90 ${
                academiclevel === "3" && "bg-secondary text-light"
              }`}
            >
              Third Secondary
            </button>
          </div>
        )}
        {academicType === "ig" && (
          <div className="flex w-full gap-10 md:justify-center md:flex-col sm:items-center">
            <button
              onClick={() => {
                setAcademiclevel("9");
              }}
              className={`rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-light hover:text-secondary active:scale-90 ${
                academiclevel === "9" && "bg-secondary text-light"
              }`}
            >
              Nine Year
            </button>
            <button
              onClick={() => {
                setAcademiclevel("10");
              }}
              className={`rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-light hover:text-secondary active:scale-90 ${
                academiclevel === "10" && "bg-secondary text-light"
              }`}
            >
              Ten Year
            </button>
          </div>
        )} */}

        {/* {academicType && academiclevel && ( */}
        {/* <Input
          className="signin-inputs search-table-placeholder h-10 pl-4 !w-1/2 !md:w-full mr-auto"
          autoFocus
          placeholder={`Search In The Lectures`}
          value={searchInput}
          onChange={(e) => {
            setSearchInput(e.target.value);
          }}
        /> */}
        {/* )} */}
      </div>

      {allLecturesloading ? (
        <Loader />
      ) : (
        <div className="lectures grid-auto-fit">
          {allLecturesData?.data?.map((card) => {
            if (card.is_chapter) {
              return (
                <SingleChapter
                  reFetch={reFetch}
                  setReFetch={setReFetch}
                  admin
                  chapter={card}
                  key={card.key}
                />
              );
            } else {
              return (
                <SingleCourse
                  reFetch={reFetch}
                  setReFetch={setReFetch}
                  admin
                  lecture={card}
                  key={card.key}
                />
              );
            }
          })}
        </div>
      )}
    </section>
  );
}

export default AdminLectures;
